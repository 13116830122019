import { Box, Stack } from "@mui/material";
import { ReactComponent as StrattoLogoIcon } from "../../assets/icons/StrattoLogoIcon.svg";
import { ButtonStratto } from "../ComponentsUtilities";
import { useTranslation } from "react-i18next";
import {
  useCurrentTypeStratto,
  useCurrentVideoStratto,
  useViewsStratto,
} from "../../hooks/hooksStratto";
import { Video } from "../../components/Video";
import { useAppDispatch } from "../../hooks";
import { useLayoutEffect, useState } from "react";
import {useParams } from "react-router-dom";
import {
  fetchAvailabilityStratto,
} from "../../store/todoStratto-actions";
import { keyframes } from "@mui/system";
import StrattoBackgroundVideo from "../../assets/Amanecer-Super.mp4"

export default function WelcomeScreen() {
  const { t } = useTranslation();
  const video = useCurrentVideoStratto();
  const type = useCurrentTypeStratto();
  

  const [requested, setRequested] = useState(false);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const moving = keyframes`
  from {opacity: 0;}
  to {margin-top: 1;}
`;

  useLayoutEffect(() => {
    if (!requested)
      dispatch(fetchAvailabilityStratto((projectId as string) ?? "768"));
    setRequested(true);
    // dispatch(
    //   setCurrentVideoStratto(views[0]?.components[0].videoUri, "forward")
    // );
  }, [dispatch, requested, projectId]);

  const views = useViewsStratto();

  return (
    <>
      {video && <Video src={video} type={type} />}

      <Box
        sx={{
          zIndex: 10,
          position: "relative",
          // backgroundColor: "#888686",
          width: "100%",
          height: "100%",
          // backgroundImage: `url('${StrattoBackground}')`,
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <video
          autoPlay
          muted
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            // zIndex: !src ? "-1" : "1",
            objectFit: "cover",
          }}>
          <source src={StrattoBackgroundVideo} type="video/mp4"></source>
        </video>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            // top: "40%",
            width: "100%",
            animationName: "downFromAbove",
            animation: `${moving}`,
            animationDuration: "0.6s",
            animationFillMode: "forwards",
          }}>
          <Box
            sx={{
              width: {
                xs: "300px",
                lg: "400px",
                xl: "420px",
              },
            }}>
            <StrattoLogoIcon
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
          <Stack
            direction="row"
            spacing={4}
            sx={{
              marginTop: "10px",
            }}>
            {views[0]?.components[0]?.buttons.map((e: any, i: any) => {
              return (
                <ButtonStratto
                  bg={e.backgroundColor}
                  key={i}
                  name={t(`${e.text}`)}
                  path={`${
                    e.goTo === "view-2" ? "property-broll" : "availability"
                  }`}
                  customStyles={{
                    padding: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                />
              );
            })}
          </Stack>
        </Stack>
        {/* <UtilitiesStratto /> */}
      </Box>
    </>
  );
}
