import { atHumInstance, availabilityStrattoInstance, strattoInstance } from "./Api";

export const availabilityService = {
    async getProject(projectId: string) {
        const { data: response } = await atHumInstance().get(`/dev/projects/${projectId}`);
        return response.data;
    }
}

export const availabilityStrattoService = {
    async getProject(projectId: string) {
        const { data: response } = await strattoInstance().get(`/prod/es/projects/${projectId}`);
        return response.data
    },
    async getFloorplans(projectId: string){
        const { data: response } = await availabilityStrattoInstance().get(`/${projectId}/external/floor-plans`);
        return response.data
    }
}