import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    videoScreen: {
      position: "relative",
      width: "100%",
      height: "auto",
      objectFit:"contain",
      // "@media (max-width: 1280px)": {
      //   height: "100%",
      //   width: "unset",
      // },
      // "webkit-full-page-media": {
      //   top:"0px",
      //   right:"0px",
      //   bottom: "0px",
      //   left:"0px",
      //   maxHeight:"100%",
      //   maxWidth:"100%"
      // },
  
    },
    buttonBroll: {
      position: "absolute",
      zIndex: "999",
      bottom: "5%",
      width: "50%",
      display:'flex',
      justifyContent: "center",
      alignItems:"center",
      "@media (max-width: 535px)": {
        bottom: "10%",
      },
    },
    containerBroll: {
      display:"flex",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      justifyContent: "center",
      alignItem: "center",
    },
    utilitiesBroll: {
      position: "absolute",
      bottom: "80px",
      right: "0px",
    },
  }));
  