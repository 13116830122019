import { useCallback, useMemo } from "react";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import { useAppDispatch } from "../../hooks";

import { Avatar, useMediaQuery } from "@mui/material";
import {
  useCurrentViewStratto,
  useViewsStratto,
} from "../../hooks/hooksStratto";
import {
  setCurrentLocationViewStratto,
  setCurrentVideoStratto,
} from "../../store/todoStratto-actions";

interface Props {
  position: "left" | "right";
  disabled: boolean;
}

const STRATTO_VIEWS_ARRAY = [1, 2, 3, 4];

export const NavigationArrowsStratto = ({ position, disabled }: Props) => {
  const dispatch = useAppDispatch();
  const currentViewStratto = useCurrentViewStratto();
  const views = useViewsStratto();
  const svgs = useMemo(()=>{
    return views?.[3]?.components?.[0]?.svgs
  },[views])


  const mobile = useMediaQuery("(max-width:1260px)");

  const prevLocation = useCallback(() => {
    const prevIndex =
      (currentViewStratto - 2 + STRATTO_VIEWS_ARRAY.length) %
      STRATTO_VIEWS_ARRAY.length;
    switch (currentViewStratto) {
      case 1:
        dispatch(
          setCurrentVideoStratto(svgs?.[3].views?.[0].videoBack, "forward")
        );
        break;
      case 2:
        dispatch(
          setCurrentVideoStratto(svgs?.[0].views?.[0].videoBack, "forward")
        );
        break;
      case 3:
        dispatch(
          setCurrentVideoStratto(svgs?.[1].views?.[0].videoBack, "forward")
        );
        break;
      case 4:
        dispatch(
          setCurrentVideoStratto(svgs?.[2].views?.[0].videoBack, "forward")
        );
        break;
    }
    setTimeout(()=>{
      dispatch(setCurrentLocationViewStratto(STRATTO_VIEWS_ARRAY[prevIndex]));
    },2000)
  }, [currentViewStratto, dispatch, svgs]);

  const nextLocation = useCallback(() => {
    const nextIndex =
      (currentViewStratto + STRATTO_VIEWS_ARRAY.length) %
      STRATTO_VIEWS_ARRAY.length;
      switch (currentViewStratto) {
        case 1:
          dispatch(
            setCurrentVideoStratto(svgs[0].views[0].video, "forward")
          );
          break;
        case 2:
          dispatch(
            setCurrentVideoStratto(svgs[1].views[0].video, "forward")
          );
          break;
        case 3:
          dispatch(
            setCurrentVideoStratto(svgs[2].views[0].video, "forward")
          );
          break;
        case 4:
          dispatch(
            setCurrentVideoStratto(svgs[3].views[0].video, "forward")
          );
          break;
      }
    setTimeout(()=>{
      dispatch(setCurrentLocationViewStratto(STRATTO_VIEWS_ARRAY[nextIndex]));
    },2000)
  }, [currentViewStratto, dispatch, svgs]);

  const arrow = useMemo(
    () =>
      position === "left" ? (
        <SubdirectoryArrowLeftIcon
          sx={{
            transform: "rotate(90deg)",
            paddingRight: "2px",
          }}
          onClick={prevLocation}
        />
      ) : (
        <SubdirectoryArrowRightIcon
          sx={{ transform: "rotate(-90deg)", paddingLeft: "2px" }}
          onClick={nextLocation}
        />
      ),
    [nextLocation, position, prevLocation]
  );

  const sx = useMemo(
    () =>
      position === "left"
        ? {
            left: "15px",
          }
        : {
            right: "15px",
          },
    [position]
  );

  return (
    <Avatar
      sx={{
        bgcolor: disabled ? "rgb(168, 168, 168)" : "rgb(74, 177, 189)",
        position: "absolute",
        cursor: disabled ? "default" : "pointer",
        top: "0",
        bottom: "0",
        margin: "auto 0",
        zIndex: mobile ? "1" : "unset",
        "&:hover": {
          opacity: 0.8,
        },
        ...sx,
      }}
      onClick={position === "left" ? prevLocation : nextLocation}>
      {arrow}
    </Avatar>
  );
};
