import {  useLayoutEffect, useMemo, useState } from "react";
import AvailabilityModal from "./AvailabilityModal";
import {
  ButtonStratto,
} from "../ComponentsUtilities";
import {
  useAppDispatch,
  useCurrentStrattoAvailabilityModal,
  useCurrentTypeStratto,
  useCurrentVideoStratto,
  useStrattoFilter,
  useStrattoSvgType,
  useViewsStratto,
} from "../../hooks/hooksStratto";
import {
  cleanStrattoFilters,
  fetchAvailabilityStratto,
  setCurrentLocationViewStratto,
  setCurrentStrattoAvailabilityModal,
  setCurrentVideoStratto,
  setStrattoLevel,
  setStrattoSvgType,
} from "../../store/todoStratto-actions";
import { StrattoLocation } from "../StrattoLocation";
import { NavigationArrowsStratto } from "../NavigationArrowsStratto";
import { Stack } from "@mui/material";
import { VideoStratto } from "../VideoStratto";
import { useLocation, useParams } from "react-router-dom";
import { FiltersStrattoAvailability } from "../ComponentsUtilities/FiltersStratto/FiltersStratto";

export default function AvailabilityScreen() {
  const {state} = useLocation()
  const videoStratto = useCurrentVideoStratto();
  const { projectId } = useParams();
  const typeStratto = useCurrentTypeStratto();
  const [scale, setScale] = useState<number>(1);
  const scaleZoom = scale.toFixed(1);
  const handleZoomIn = () => setScale(scale + 0.1);
  const handleZoomOut = () => scale > 1 && setScale(scale - 0.1);
  const openModal = useCurrentStrattoAvailabilityModal();
  const dispatch = useAppDispatch();
  const strattoSvgType = useStrattoSvgType()
  const [requested, setRequested] = useState(false);
  const { levels } = useStrattoFilter();
  const views = useViewsStratto();
  const svgs = useMemo(()=>{
    return views?.[3]?.components[0].svgs
  },[views])


  useLayoutEffect(()=>{
    if(!requested){
      // dispatch(fetchAvailabilityStrattoUnits((projectId as string) ?? "768"))
      dispatch(fetchAvailabilityStratto((projectId as string) ?? "768"));
    }
    setRequested(true)
    if (state?.isHasTransition) {
      dispatch(setStrattoSvgType('3d'))
      dispatch(setCurrentLocationViewStratto(2));
      setTimeout(
        () =>
          dispatch(setCurrentVideoStratto(svgs[1].views[0].video, "forward")),
        50
      );
      setTimeout(() => dispatch(setCurrentLocationViewStratto(3)), 500);
      window.history.replaceState({}, '')
    }

    return () => {
      clearTimeout(
        setTimeout(() => dispatch(setCurrentLocationViewStratto(3)), 500)
      );
      clearTimeout(
        setTimeout(
          () =>
            dispatch(setCurrentVideoStratto(svgs[1].views[0].video, "forward")),
          50
        )
      );
    };
  },[dispatch, requested, projectId, svgs, state])

  useLayoutEffect(()=>{
    dispatch(cleanStrattoFilters())
    dispatch(setCurrentStrattoAvailabilityModal(false))
    if(strattoSvgType === "2d" && levels?.length) dispatch(setStrattoLevel(levels?.[0].value))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch, strattoSvgType])


  const handleClose = () => {
    dispatch(setCurrentStrattoAvailabilityModal(false));
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#000",
      }}>
      {!openModal && (
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}>

          <FiltersStrattoAvailability handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut}/>
          {/* <UtilitiesStratto /> */}
          <Stack
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",
              overflow: "hidden",
              position: "relative",
            }}>
            {videoStratto && (
              <VideoStratto src={videoStratto} type={typeStratto} />
            )}
            <StrattoLocation scaleZoom={scaleZoom} />

            {strattoSvgType === "3d" && (
              <NavigationArrowsStratto position="left" disabled={false} />
            )}
            {strattoSvgType === "3d" && (
              <NavigationArrowsStratto position="right" disabled={false} />
            )}
            
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "fixed",
                bottom: "40px",
              }}>
              <ButtonStratto
                name="Amenidades"
                customStyles={{ marginRight: "20px" }}
                path="amenities-broll"
              />
              <ButtonStratto name="Agenda tu visita" onClick={()=>window.open("https://rigabosques.com/contact.html", "_blank")}/>
            </div>
          </Stack>
        </Stack>
      )}
      {openModal && (
        <AvailabilityModal open={openModal} onClose={handleClose} />
      )}
    </div>
  );
}
