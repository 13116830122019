import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { availabilitySlice } from './todo-slice'
import { availabilityStrattoSlice } from './todoStratto-slice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key:"root",
  storage:storage,
  whiteList:["auth"]
}
const appReducer = combineReducers({
  availability: availabilitySlice.reducer,
  availabilityStratto: availabilityStrattoSlice.reducer 
})

const persistedReducer = persistReducer(persistConfig,appReducer)
const store = configureStore({
  reducer:persistedReducer
})
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;