import axios from "axios";

export const atHumInstance = () => axios.create({
    baseURL: "https://us-central1-avria-production.cloudfunctions.net/showroom_api_prod/v1",
});

export const strattoInstance = () => axios.create({
    baseURL: "https://api.athum.com/v2"
})

export const availabilityStrattoInstance = ()=>axios.create({
    baseURL:'https://api.athum.com/v1/projects'
})